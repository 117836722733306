
import CloudFun, { computed, defineComponent, ref } from '@cloudfun/core';
import Stepper, { IStepperNode, IStepperOptions } from '@/cloudfun/components/Stepper.vue';
import { VxeFormInstance, VxeFormProps } from 'vxe-table';
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue';
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import FileUploader from '@/cloudfun/components/FileUploader.vue'
import { VueUploadItem } from 'vue-upload-component';

export default defineComponent({
  components: {
    Stepper,
    CheckBoxList,
    SelectBox,
    FileUploader
  },
  setup () {
    const model = CloudFun.current?.model
    const stepper = ref<any>();

    // #region step 1

    const roleForm = ref<VxeFormInstance>();
    const roleFormOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 24,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', clearable: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Description',
          title: '說明',
          span: 24,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', clearable: true },
            attrs: { type: 'text' }
          }
        },
        {
          title: '權限',
          span: 24,
          slots: { default: 'form-permissions' }
        },
      ],
      rules: {
        Name: [{ type: 'string', required: true }]
      }
    }

    const permissionItems = computed(() => {
      const items: any[] = []
      for (const permission of Object.values(model?.enums.SystemPermission || {})) {
        if (permission.GroupName != null) {
          let group = items.find(e => e.name === permission.GroupName)
          if (!group) items.push(group = { name: permission.GroupName, items: [], order: 0 })
          group.items.push({ name: permission.Name, value: permission.Value, order: permission.Order })
          if (group.order < permission.Order) group.order = permission.Order
        } else items.push({ name: permission.Name, value: permission.Value, order: permission.Order })
      }
      return items.sort((a: any, b: any) => a.order - b.order)
    })

    // #endregion
    // #region step 2

    const uploader = ref<any>({})
    const userForm = ref<VxeFormInstance>();
    const userFormOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: 'right',
      span: 3,
      items: [
        { field: 'Name', title: '名稱', span: 12, itemRender: { name: '$input', props: { placeholder: '名稱' } } },
        { field: 'Status', title: '狀態', span: 12, itemRender: { name: '$select', options: model ? Object.values(model.enums.UserStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'Account', title: '帳號', span: 12, itemRender: { name: '$input', props: { placeholder: '帳號' } } },
        { field: 'Password', title: '密碼', span: 12, itemRender: { name: '$input', props: { type: 'password', placeholder: '密碼', autocomplete: 'new-password' } } },
        { field: 'Email', title: 'Email', span: 24, itemRender: { name: '$input', props: { placeholder: 'Email' } } },
        { field: 'RoleIds', title: '角色', span: 24, slots: { default: 'column-role-ids' } },
        // { field: 'OnlineString', title: '是否在線', span: 12, itemRender: { name: '$input', props: { placeholder: '是否在線', disabled: 'true' } } },
        // { field: 'LoginIp', title: 'IP位址', span: 12, itemRender: { name: '$input', props: { placeholder: 'IP位址', disabled: 'true' } } },
        // { field: 'LoginTimeString', title: '登入時間', span: 12, itemRender: { name: '$input', props: { placeholder: '登入時間', disabled: 'true' } } },
        // { field: 'LogoutTimeString', title: '登出時間', span: 12, itemRender: { name: '$input', props: { placeholder: '登出時間', disabled: 'true' } } },
        { field: 'Photo.Uri', title: '頭像', span: 24, slots: { default: 'column-photo-and-remark' } },
      ],
      rules: {
        Name: [{ required: true }],
        Email: [{
          required: true,
          message: 'Email格式錯誤',
          pattern: new RegExp('^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$')
        }],
        Account: [{ required: true }],
        Password: [{
          required: false,
          validator: (params) => {
            if (params.itemValue) {
              const regex = new RegExp("^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)")
              if (!regex.test(params.itemValue)) return new Error('須8碼以上含大小寫英文、數字')
            } else if (!params.data.Id) return new Error('新用戶須設定密碼')
          }
        }],
        RoleIds: [{
          required: true, validator: (params) => { if (!params.itemValue?.length) return new Error('須選擇至少一個以上的角色') }
        }]
      }
    }

    const roleSelectorOptions: SelectBoxOptions = {
      rowId: 'Id',
      transfer: true,
      placeholder: '選擇角色',
      textField: 'Name',
      valueField: 'Id',
      columns: [{ field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true }],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("role/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("role/query", params), // eslint-disable-line
      }
    }

    // #endregion

    const stepperOptions = ref<IStepperOptions>({
      nodes: [
        {
          step: 1,
          title: '建立角色',
          data: {},
          template: 'step1',
          skipable: true,
          reset: async (node) => { node.data = {} },
          validate: async () => { try { await roleForm.value?.validate(); return true; } catch { return false; } },
        },
        {
          step: 2,
          title: '建立用戶',
          data: {},
          template: 'step2',
          reset: async (node) => { node.data = {} },
          validate: async () => { try { await userForm.value?.validate(); return true; } catch { return false; } },
        },
      ],
      height: 590
    });

    return {
      stepper,
      stepperOptions,
      roleForm,
      roleFormOptions,
      permissionItems,
      userForm,
      userFormOptions,
      roleSelectorOptions,
      uploader,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`
    }
  },
  methods: {
    async uploaderFilter (current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent()
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert('未支援此種圖片格式')
        return prevent()
      }
    },
    next(params: { from: IStepperNode, to: IStepperNode, nodes: IStepperNode[] }, callback: any) {
      let success = false;
      switch (params.from.step) {
        case 1:
          try {
            if (this.roleForm) this.roleForm.dispatchEvent('submit', undefined, new Event('submit'));
            success = true;
          } catch { }
          if (success) callback();
          break;
      }
    },
    complete(_: IStepperNode[], callback: any) {
      let success = false;
      try {
        if (this.userForm) this.userForm.dispatchEvent('submit', undefined, new Event('submit'));
        success = true;
      } catch { }
      if (success) callback();
    },
    submit(node: IStepperNode, nodes: IStepperNode[]) {
      switch (node.step) {
        case 1:
          if (node.data.Id) return;
          this.$model.dispatch('role/insert', node.data).then(
            success => {
              node.data = success;
              if (!nodes[1].data) nodes[1].data = {};
              if (!nodes[1].data.RoleIds) nodes[1].data.RoleIds = [];
              nodes[1].data.RoleIds.push(success.Id);
            },
            failure => {
              this.$send('error', failure.message || failure);
              this.stepper.jump(1, true);
            }
          )
          break;
        case 2:
          if (node.data.Id) return;
          this.$model.dispatch('user/insert', node.data).then(
            success => {
              node.data = success;
            },
            failure => {
              this.$send('error', failure.message || failure);
              this.stepper.jump(2, true);
            }
          )
          break;
      }
    },
  }
});
